<!-- 重点管控区域 -->
<template>
    <div class="area-item-container">
        <img src="../../../../../assets/screen/icon_01.png" v-if="info.type==1" width="46" height="46" />
        <img src="../../../../../assets/screen/icon_02.png" v-if="info.type==2" width="46" height="46" />
        <img src="../../../../../assets/screen/icon_03.png" v-if="info.type==3" width="46" height="46" />
        <img src="../../../../../assets/screen/icon_04.png" v-if="info.type==4" width="46" height="46" />
        <div class="type-containter">
            <div class="sum">{{info.count}}</div>
            <div class="label">{{info.type | areaTypeFormat}}</div>
        </div>
        <div class="company-container">
            <div class="sum">{{info.totalCount}}</div>
            <div class="label">{{info.companyName=='' ? '暂无公司' : info.companyName}}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "area-item",
    props: {
        info: {
            type: Object,
            default: ()=>{}
        }
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="stylus" scoped>
.area-item-container
    display flex
    color #BEDAF9
    text-align left
    min-height 64px
    margin-top 20px
    img
        margin-right 14px
    .type-containter
        flex 1
        margin-right 5px
        .sum
            color #F0D544
            font-weight 700
            font-size 22px
        .label
            font-size 14px
            font-weight 400
        
    .company-container
        flex 1
        .sum
            color #50D1FF
            font-weight 700
            font-size 22px
        .label
            font-size 14px
            font-weight 400
</style>
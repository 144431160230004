<template>
    <div class="week-item-container">
        <div class="honor-name">
            {{ honorName }}
        </div>
        <div class="data-bar">
            <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="countPercent(count, totalCount)"
                :format="format">
            </el-progress>
        </div>
        <div class="company">
            {{ companyOrProject }}
            （{{ count }}/{{ totalCount }}）
        </div>
    </div>
</template>
<script>
export default {
    name: "week-item",
    props: {
        honorName: {
            type: String,
            default: ''
        },
        companyOrProject: {
            type: String,
            default: ''
        },
        count: {
            type: Number,
            default: 0
        },
        totalCount:{
            type: Number,
            default: 0
        }
    },
    data() {
        return {

        }
    },
    methods:{
        // 计算百分比
        countPercent(val1, val2){
            let num = val1/val2*100
            return parseFloat(num.toFixed(2)); 
        },
        format(percentage){
            return `${percentage}%`;
        },
    }
}
</script>
<style lang="stylus" scoped>
.week-item-container
    margin-bottom 18px
    display flex
    flex-direction row
    .honor-name
        width 120px 
        text-align right
        padding-right 10px
        color #fff
    .company
        width 220px
        padding-left 10px
        color #fff
    .data-bar
        flex:1

</style>